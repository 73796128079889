import React, { useContext } from 'react';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import ContentBox from '../../common/ContentBox/ContentBox';
import useAppearenceHook from './Appearence.hook';
import AppButton from '../../common/Forms/AppButton/AppButton';
import ColorInput from '../../common/Forms/Inputs/ColorInput/ColorInput';
import styles from './Appearence.styles';
import ImageInput from '../../common/Forms/Inputs/ImageInput/ImageInput';
import Loading from '../../common/Loader/Loading';
import { AppContext } from '../../../App.context';
import InfoView from 'Components/common/InfoView/InfoView';
import { InfoCircleOutlined } from '@ant-design/icons';
import SaveButton from 'Components/common/SaveButton/SaveButton';
import ImageSlider from './ImageSlider/ImageSlider';

export default function Appearence() {
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);
  const { state, actions } = useAppearenceHook();

  return (
    <DefaultLayout title={translate('layout.appearence')}>
      <ContentBox title={translate('appearence.checkInTitle')}>
        {state.isLoading || !appState?.hotelList || !appState?.hotelList.length ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {state.isLoading ? (
              <Loading />
            ) : (
              <InfoView text={translate('emptyHotelListError')} icon={<InfoCircleOutlined />} />
            )}
          </div>
        ) : (
          <div style={styles.flexColumn}>
            <div style={styles.flexColumn}>
              <ImageInput
                name={translate('appearence.logo')}
                value={state.logo}
                setValue={actions.updateValue}
                id={'logo'}
                hotelId={state.hotel?.key}
                type={'normal'}
              />
              <ColorInput
                name={translate('appearence.mainColor')}
                value={state.color1}
                setValue={actions.updateValue}
                id={'color1'}
              />
              <ColorInput
                name={translate('appearence.secondaryColor')}
                value={state.color2}
                setValue={actions.updateValue}
                id={'color2'}
              />
              <ColorInput
                name={translate('appearence.bgColor')}
                value={state.bgColor}
                setValue={actions.updateValue}
                id={'bgColor'}
              />
            </div>
            <div style={styles.buttonContainer}>
              <SaveButton text={translate('appearence.apply')} action={actions.updateApearence} />
            </div>
          </div>
        )}
      </ContentBox>
      <ContentBox title={translate('appearence.kioskTitle')}>
        {state.isLoading || !appState?.hotelList || !appState?.hotelList.length ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {state.isLoading ? (
              <Loading />
            ) : (
              <InfoView text={translate('emptyHotelListError')} icon={<InfoCircleOutlined />} />
            )}
          </div>
        ) : (
          <div style={styles.flexColumn}>
            <div style={styles.flexColumn}>
              <ImageInput
                name={translate('appearence.kioskLogo')}
                value={state.kioskLogo}
                setValue={actions.updateValue}
                id={'kioskLogo'}
                hotelId={state.hotel?.key}
                type={'kiosk'}
              />
              <ColorInput
                name={translate('appearence.mainColorKiosk')}
                value={state.kioskTitleColor}
                setValue={actions.updateValue}
                id={'kioskTitleColor'}
              />
              <ColorInput
                name={translate('appearence.kioskBg')}
                value={state.kioskBgColor}
                setValue={actions.updateValue}
                id={'kioskBgColor'}
              />
            </div>
            <div style={styles.buttonContainer}>
              <SaveButton text={translate('appearence.apply')} action={actions.updateKiosk} />
            </div>
          </div>
        )}
      </ContentBox>
      <ContentBox title={translate('appearence.chainTitle')}>
        {state.isLoading || !appState?.hotelList || !appState?.hotelList.length ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {state.isLoading ? (
              <Loading />
            ) : (
              <InfoView text={translate('emptyHotelListError')} icon={<InfoCircleOutlined />} />
            )}
          </div>
        ) : (
          <div style={styles.flexColumn}>
            <ImageInput
              name={translate('appearence.logo')}
              value={state.chainLogo}
              setValue={actions.updateValue}
              id={'chainLogo'}
              chainId={state.chainId}
            />
            <div style={styles.buttonContainer}>
              <AppButton
                text={translate('appearence.apply')}
                action={() => actions.updateChain()}
              />
            </div>
          </div>
        )}
      </ContentBox>
      {[3252, 3517].includes(getSelectedHotel()?.key) && (
        <ContentBox title={translate(`appearence.imageSliderTitle`)}>
          <ImageSlider images={state.sliderImages} />
        </ContentBox>
      )}
    </DefaultLayout>
  );
}
